import * as React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Polygon } from "@fitplan/lib/components/Chevron/Polygon";
import { css } from "@emotion/core";


import localize from '../hoc/Localize';
import PageWrapper from "../components/pageWrapper/PageWrapper";
import {Subscribe} from "../components/subscribe";
import Grid from '../components/grid/grid';
import './plans.scss';

const PlansPage = props => {
  const {
    planType,
  } = props.data.sanityPlansPage;
  const { allFitplanPlans } = props.data;
  return (
    <PageWrapper {...props}>
      <Helmet
        title={planType.title}
        meta={[
          {
            name: 'description',
            content: planType.description,
          },
          {
            name: 'keywords',
            content: planType.keywords.join(', '),
          },
        ]}
      />
      <div className="plans">
        <Grid component="plans" cards={allFitplanPlans} />
        <Polygon polygonColor="#1b1c1c" />
        <Subscribe />
      </div>
      <div css={css`
        background-color: #212121;
      `}>
        <Polygon polygonColor="#111213" borderColor="rgb(0, 255, 179)"  />
      </div>
    </PageWrapper>
  );
};

PlansPage.propTypes = {
  data: PropTypes.shape({
    sanityPlans: PropTypes.shape({}).isRequired,
    allFitplanPlans: PropTypes.shape({}).isRequired,
  }).isRequired,
};

export const query = graphql`
  query fitplansQuery {
    allFitplanPlans {
      edges {
        node {
          slug
          planImage
          planName
          firstName
          lastName
          femaleBootyGains
          femaleBuildAndBurn
          femaleShredFat
          femaleToneAndTighten
          maleAthleticPerformance
          maleBulkUp
          maleShredFat
          planType
          daysCount
          url
          link
        }
      }
    }
    sanityPlansPage {
      header {
        _type
        en
        es
      }
      trialButton {
        _type
        en
        es
      }
      workoutButton {
        _type
        en
        es
      }
      planType {
        title {
          _type
          en
          es
        }
        description {
          _type
          en
          es
        }
        keywords {
          _type
          en
          es
        }
      }
    }
  }
`;

export default localize(PlansPage);
